import request from '@/utils/request'
// 获取小区列表[适用于下拉列表选择]
export function WeGetRegionList(query) {
    return request({
        url: 'Region/WeGetRegionList',
        method: 'get',
        params: query
    })
}
// 获取楼宇信息列表[小程序，适用于下拉列表]
export function WeGetBuildList(query) {
    return request({
        url: 'Build/WeGetBuildList',
        method: 'get',
        params: query
    })
}
// 获取楼宇单元列表[小程序，适用于下拉列表]
export function WeGetBuildUnitList(query) {
    return request({
        url: 'Build/WeGetBuildUnitList',
        method: 'get',
        params: query
    })
}
// 获取楼层列表[小程序，适用于下拉列表]
export function WeGetBuildFloorList(query) {
    return request({
        url: 'Build/WeGetBuildFloorList',
        method: 'get',
        params: query
    })
}
// 获取房屋列表[小程序，适用于下拉列表]
export function WeGetBuildRoomList(query) {
    return request({
        url: 'Build/WeGetBuildRoomList',
        method: 'get',
        params: query
    })
}
// 获取指定类别的代码列表
export function GetCodeEntrysList(query) {
    return request({
        url: 'Code/GetCodeEntrysList',
        method: 'get',
        params: query
    })
}

export function SaveCheck(data) {
    return request({
        url: 'Vaccine/SaveCheck',
        method: 'post',
        data
    })
}
export function SaveReporting(data) {
    return request({
        url: 'Vaccine/SaveReporting',
        method: 'post',
        data
    })
}
export function SaveDesireHelp(data) {
    return request({
        url: 'Desire/SaveDesireHelp',
        method: 'post',
        data
    })
}
export function WeBindRegion(data) {
    return request({
        url: 'Member/WeBindRegion',
        method: 'post',
        data
    })
}
export function WxGetDesireHelpList(query) {
    return request({
        url: 'Desire/WxGetDesireHelpList',
        method: 'get',
        params: query
    })
}
export function WxGetDesireHelpPage(query) {
    return request({
        url: 'Desire/WxGetDesireHelpPage',
        method: 'get',
        params: query
    })
}
export function WxHandlerDesireHelp(data) {
    return request({
        url: 'Desire/WxHandlerDesireHelp',
        method: 'post',
        data
    })
}
export function WxGetHandlerDesireHelp(query) {
    return request({
        url: 'Desire/WxGetHandlerDesireHelp',
        method: 'get',
        params: query
    })
}
export function WxApplyResident(data) {
    return request({
        url: 'GHCapp/WxApplyResident',
        method: 'post',
        data
    })
}
// 会员获取绑定信息详情
export function WeGetMemberInfo(params) {
    return request({
        url: 'GHCapp/WeGetMemberInfo',
        method: 'get',
        params
    })
}
// 微信获取便民服务清单
export function WeGetServiceList(params) {
    return request({
        url: 'GHCapp/WeGetTicketList',
        method: 'get',
        params
    })
}
export function WeEnrolActivity(data) {
    return request({
        url: 'Activity/WeEnrolActivity',
        method: 'post',
        data
    })
}
export function WxSignActive(data) {
    return request({
        url: 'GHCapp/WxSignActive',
        method: 'post',
        data
    })
}
export function WxScoreDetailList(params) {
    return request({
        url: 'GHCapp/WxScoreDetailList',
        method: 'get',
        params
    })
}
export function WeGetAppletOrganList(params) {
    return request({
        url: 'Organ/WeGetAppletOrganList',
        method: 'get',
        params
    })
}