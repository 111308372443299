<template>
  <div class="yimiao">
    <div>
      <div
        style="
          border-bottom: 1px solid #e6e6e6;
          padding: 10px 15px;
          position: relative;
        "
      >
        <div style="display: inline-block; width: 60px">
          <img style="width: 100%" src="../../assets/xuqiu.png" alt="" />
        </div>
        <div style="display: inline-block; position: absolute; top: 30px;left:100px">
          <div style="font-size: 18px">疫苗接种排查登记</div>
        </div>
      </div>
      <div>
        <!-- <van-cell-group>
          <van-field
            v-model="datafrom.RIdTitle"
            required
            @click="idListRId = true"
            readonly="readonly"
            label="所住小区"
            placeholder="请选择您所住小区"
          />
          <van-popup v-model="idListRId" position="bottom" get-container="body">
            <van-picker
              show-toolbar
              :columns="ridlist"
              value-key="Title"
              @cancel="idListRId = false"
              @confirm="onidListRId"
            >
            </van-picker>
          </van-popup>
        </van-cell-group> -->
        <van-cell-group>
          <van-field
            v-model="datafrom.Name"
            required
            label=" 姓 名 "
            placeholder="请输入姓名"
          />
        </van-cell-group>
        <van-cell-group>
          <van-field
            v-model="datafrom.IDCard"
            label="身份证号"
            required
            placeholder="请输入身份证号码"
          />
        </van-cell-group>
        <van-cell-group>
          <van-field
            v-model="datafrom.Mobile"
            label="联系电话"
            required
            placeholder="请输入联系电话"
          />
        </van-cell-group>
        <van-cell-group>
          <van-field
            v-model="datafrom.Address"
            required
            label="家庭住址"
            placeholder="请输入现家庭住址"
          />
        </van-cell-group>
        <van-cell-group>
          <van-field
            v-model="datafrom.StatusName"
            required
            @click="Status = true"
            readonly="readonly"
            label="接种疫苗情况"
            placeholder="请选择接种疫苗情况"
          />
          <van-popup v-model="Status" position="bottom" get-container="body">
            <van-picker
              show-toolbar
              :columns="yimiaolist"
              value-key="Name"
              @cancel="Status = false"
              @confirm="onStatus"
            >
            </van-picker>
          </van-popup>
        </van-cell-group>
        <van-cell-group>
          <van-field
            v-model="datafrom.Remark"
            label="备注"
            placeholder="请输入备注"
          />
        </van-cell-group>
      </div>
      <div class="buttons">
        <!-- <van-button type="primary">提交并录入下一条</van-button> -->
        <van-button type="info" @click="postfrom()">提交</van-button>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import { Dialog } from "vant";
import { Toast } from "vant";
import { setOpenId, getOpenId } from "@/utils/auth";
Vue.use(Toast);
import {
  WeGetRegionList,
  WeGetBuildList,
  WeGetBuildUnitList,
  WeGetBuildFloorList,
  WeGetBuildRoomList,
  GetCodeEntrysList,
  SaveCheck,
} from "@/api/yeguang";
export default {
  components: {
    [Dialog.Component.name]: Dialog.Component,
  },
  data() {
    return {
      ridlist: [],
      BIdlist: [],
      BUIdlist: [],
      BFIdlist: [],
      BRIdlist: [],
      diqu: [],
      guanxi: [],
      minzu: [],
      xveli: [],
      zhuangtai: [],
      hunyin: [],
      NativeCantonCodedata: [],
      sexlist: [
        { id: "1", Name: "男" },
        { id: "2", Name: "女" },
      ],
      yimiaolist: [
        { id: "1", Name: "未接种" },
        { id: "2", Name: "第一针" },
        { id: "3", Name: "第二针" },
        { id: "4", Name: "第三针" },
      ],
      idList: {
        organCode: "4103110201",
        RId: undefined,
      },
      datafrom: {
        ID: 0, //排查id、
        Name: "", //姓名、
        IDCard: "", //身份证、
        Mobile: "", //联系方式、
        Address: "", //家庭住址、
        Status: "", //接种状态（1未接种、2第一针、3第二针）、
        Remark: "", //备注、
        OpenID: "", //群众openid
      },
      idListRId: false,
      addshow: false,
      verifyshow: true,
      sexshow: false,
      Nationshow: false,
      Status: false,
    };
  },
  created() {
    this.RegionList();
  },
  methods: {
    // 提交
    postfrom: function () {
      if (this.datafrom.Name == "") {
        Toast.fail("请输入姓名");
        return false;
      }
      if (this.datafrom.MobileTel == "") {
        Toast.fail("请输入联系电话");
        return false;
      }
      if (this.datafrom.IdNumber == "") {
        Toast.fail("请输入身份证号码");
        return false;
      }
      if (this.datafrom.jia == "") {
        Toast.fail("请输入家庭住址");
        return false;
      }
      if (this.datafrom.StatusName == "") {
        Toast.fail("请选择疫苗接种情况");
        return false;
      }
      this.datafrom.OpenID = getOpenId();
      // this.datafrom.OpenID = 9465456165156;
      SaveCheck(this.datafrom).then((res) => {
        console.log(res);
        if (res.data.code == 0) {
          Dialog.alert({
            title: "提示",
            message: "提交成功!",
          }).then(() => {
            this.$router.push({
        name: "register",
      });
          });
        } else {
          Dialog.alert({
            title: "提示",
            message: "提交失败" + res.data.msg,
          }).then(() => {
            // on close
          });
        }
      });
    },
    // 小区列表
    RegionList: function () {
      WeGetRegionList({ organCode: "4103110201" }).then((res) => {
        this.ridlist = res.data.data;
      });
    },
    // 房屋列表
    BuildRoomList: function (val) {
      WeGetBuildRoomList({
        bId: this.idList.BId,
        buId: this.idList.buId,
        bfId: val,
      }).then((res) => {
        console.log(res);
        this.BRIdlist = res.data.data;
      });
    },
    // 小区选择器
    onidListRId(val) {
      this.datafrom.RId = val.RId;
      this.datafrom.RIdTitle = val.Title;
      this.idListRId = false;
      this.BuildList(val.RId);
    },
    // 性别选择器
    onsexshow(val) {
      this.datafrom.sex = val.id;
      this.datafrom.sexName = val.Name;
      this.sexshow = false;
    },
    // 疫苗选择器
    onStatus(val) {
      this.datafrom.Status = val.id;
      this.datafrom.StatusName = val.Name;
      this.Status = false;
    },
  },
};
</script>
<style>
.yimiao .inputFrom {
  margin: 15px 8%;
}
.yimiao .inputFrom input {
  width: 80%;
  margin: 8px 8%;
  padding: 8px 5px;
  font-size: 14px;
  border: 1px solid #e6e6e6;
}
.yimiao .buttons {
  text-align: center;
}
.yimiao .buttons button {
  width: 90%;
  margin: 25px 5%;
  border-radius: 10px;
}
</style>